<template>
  <div
    id="app"
    class="vertical-layout vertical-compact-menu"
    :class="
      isAuthenticated
        ? '2-columns menu-expanded fixed-navbar'
        : '1-column bg-full-screen-image menu-expanded blank-page pace-done noLogin'
    "
    data-open="click"
    data-menu="vertical-compact-menu"
    :data-col="isAuthenticated ? '2-columns' : '1-columns'"
  >
    <Header v-if="isAuthenticated" />
    <LeftMenu v-if="isAuthenticated" />
    <div class="app-content content">
      <!-- <TopBanner v-if="isAuthenticated" /> -->
      <router-view />
    </div>
    <Footer />
    <!-- <PromotionPopup /> -->
     <MaintenanceModal />
    <OverlayLoader />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LeftMenu from '@/components/navigation/LeftMenu.vue';
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import '@/assets/css/pages/dashboard-ico.css';
// import PromotionPopup from './components/global/PromotionPopup.vue';
// import TopBanner from './components/global/TopBanner.vue';
import MaintenanceModal from './components/global/MaintenanceModal.vue';

export default {
  name: 'Home',
  components: {
    LeftMenu,
    Header,
    Footer,
    // PromotionPopup,
    // TopBanner,
    MaintenanceModal
  },
  computed: {
    ...mapGetters('customer', ['isAuthenticated']),
    showTopBanner() {
      return true;
    }
  },
};
</script>

<style lang="scss">
$spaceamounts: (5, 8, 10, 15, 16, 20, 24, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100);
$sides: (top, bottom, left, right);

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

#account-login {
  .form-label-group {
    min-height: 74px;

    .form-control {
      padding-top: 17px !important;
      padding-bottom: initial !important;
    }

    &.-regular {
      min-height: initial;
    }
  }
}

.multiselect--active {
  z-index: 50 !important;
}

.multiselect__input {
  &:focus {
    padding-top: 0 !important;
  }
}

.multiselect.is-invalid .multiselect__tags {
  border-color: #dc3545;
}

.form-label-group,
.form-group {
  .error__message {
    display: block;
    min-height: 17px;
  }
}

.is-valid ~ .error__message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.is-invalid ~ .error__message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.btn-gradient-primary {
  text-transform: none;
  background-image: -webkit-gradient(linear, left top, right top, from(#a351cb), color-stop(51%, #642583), to(#a351cb));
  background-image: -webkit-linear-gradient(left, #a351cb 0%, #642583 51%, #a351cb 100%);
  background-image: -moz-linear-gradient(left, #a351cb 0%, #642583 51%, #a351cb 100%);
  background-image: -o-linear-gradient(left, #a351cb 0%, #642583 51%, #a351cb 100%);
  background-image: linear-gradient(to right, #a351cb 0%, #642583 51%, #a351cb 100%);
  -webkit-box-shadow: 0px 0px 16px 0 rgb(100, 37, 131);
  box-shadow: 0px 0px 16px 0 rgb(100, 37, 131);
}
.btn-outline-primary {
  border-color: #642583;
  color: #642583;
  &:hover {
    background-color: #642583;
    border-color: #642583;
  }
}
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background-color: #642583;
  border-color: #642583;
}
.form-control:focus ~ label {
  padding-top: -webkit-calc(var(--input-padding-y) / 3);
  padding-top: -moz-calc(var(--input-padding-y) / 3);
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: -webkit-calc(var(--input-padding-y) / 3);
  padding-bottom: -moz-calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 11px;
  color: #777;
}

.multiselect__placeholder,
.multiselect__single {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.vertical-compact-menu .main-menu .navigation > li > a > span {
  visibility: visible;
  opacity: 1;
  font-size: 0.7rem;
  width: 100% !important;
}

.form-label-group > label {
  width: calc(100% - 50px);
}

.showPassword {
  height: 24px;
  width: 24px;
  position: absolute;
  right: 15px;
  top: 15px;
}

.passwordEye {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='%23777777' d='M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z'%3E%3C/path%3E%3Ccircle stroke='%23777777' cx='12' cy='12' r='3'%3E%3C/circle%3E%3C/svg%3E");
}

.passwordEyeOff {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' %3E%3Cpath stroke='%23777777' d='M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24'%3E%3C/path%3E%3Cline stroke='%23777777' x1='1' y1='1' x2='23' y2='23'%3E%3C/line%3E%3C/svg%3E");
}

@media only screen and (max-width: 768px) {
  html body .content .content-wrapper {
    padding: 5rem 1rem 1rem;
  }
  .nav.nav-tabs {
    min-height: 40px;
    height: auto !important;
  }
  .nav.nav-tabs .nav-item {
    min-height: 40px;
    height: auto !important;
  }
  body.vertical-compact-menu .content,
  body.vertical-compact-menu .footer {
    margin-left: 0 !important;
  }
}

.pull-up {
  perspective: 1000px;
  -moz-perspective: 1000px;
  -webkit-perspective: 1000px;
}

.pull-up-only-shadow {
  transition: box-shadow 0.25s ease;

  &:hover {
    box-shadow: rgba(255, 255, 255, 1) 0 0 0 2px, rgba(50, 50, 93, 0.2) 0 0 10px 2px;
  }
}

.iban-input {
  padding-left: 36px;
}

.iban-country-code {
  position: absolute;
  z-index: 9;
  height: 54.5px;
  display: flex;
  align-items: center;
  left: 15px;
  text-transform: uppercase;
}

.progress-status {
  .progress-type {
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0.4);
    padding: 3px 5px;
    border-radius: 5px;

    &.deposit {
      color: #27ae60;
      border-color: #27ae60;
    }

    &.manualtransaction {
      color: #27ae60;
      border-color: #27ae60;
    }

    &.transfer {
      color: #2980b9;
      border-color: #2980b9;
    }

    &.withdraw {
      color: #d35400;
      border-color: #d35400;
    }

    &.quicksale-buy {
      color: #27ae60;
      border-color: #27ae60;
    }

    &.quicksale-sell {
      color: #d35400;
      border-color: #d35400;
    }
  }

  &.waiting {
    .progress-text {
      color: #007bff;
    }
  }

  &.completed {
    .progress-text {
      color: #28a745;
    }
  }

  &.canceled,
  &.error {
    .progress-text {
      color: #dc3545;
    }
  }

  &.progressing {
    .progress-text {
      color: #17a2b8;
    }
  }

  &.waiting-finance-approval,
  &.waiting-user-approval {
    .progress-text {
      color: #ffc107;
    }
  }

  &.approved {
    .progress-text {
      color: #28a745;
    }
  }
}

.primeTrustWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
  .primeTrustText {
    margin-right: 20px;
    font-size: 16px;
    padding-top: 6px;
    font-weight: bold;
  }
  img {
    width: 130px;
    height: auto;
  }
}
.text-primary {
  color: #642583 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #491068 !important;
}
</style>
