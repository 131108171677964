<template>
  <modal v-if="showMaintenanceModal" ref="electronicCommunicationModal" @onClose="closeModal" :modalDialogStyle="modalStyle">
    <template v-slot:header>
      <h1>{{ $t('commons.important_info') }}</h1>
    </template>
    <template v-slot:body>
      <div class="d-flex flex-column">
        <span class="mb-1" v-for="(info, infoKey) in $t('micaRequlationInEuropeInfo')" :key="`info-${infoKey}`">{{ info }}</span>
        <div class="mt-1">
          <a class="btn btn-gradient-primary w-100" href="https://www.bitdenex.com/">{{ $t('continueToBitdx') }}</a>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Modal from './Modal.vue';

export default {
  name: 'MaintenanceModal',
  components: {
    Modal
  },
  computed: {
    ...mapState({
      showMaintenanceModal: (state) => state.global.maintenanceModal
    }),
    modalStyle() {
      const windowHeight = `${window.innerHeight - 200}px`;
      return { maxHeight: windowHeight, minHeight: '300px' };
    }
  },
  methods: {
    ...mapActions('global', ['CHANGE_MAINTENANCE_MODAL_STATUS']),
    closeModal() {
      /* this.CHANGE_MAINTENANCE_MODAL_STATUS(false);
      document.querySelector('body').classList.remove('overflow-hidden'); */
      window.location.href = 'https://www.bitdenex.com/';
    }
  },
  created() {
    if (this.showMaintenanceModal) {
      document.querySelector('body').classList.add('overflow-hidden');
      document.querySelector('body').classList.remove('menu-open');
    }
  }
};
</script>
<style lang="scss" scoped>
.btn {
  box-shadow: none !important;
}
</style>
